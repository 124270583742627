import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { FC, useCallback, useEffect, useState } from "react";
import { GearIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Link } from "@/components/ui/link";
import { FIRMWARE_INSTALLERS, FirmwareInstallerType } from "./firmware";
import { createServerUrl } from "@/utils";

type Props = {};

const FirmwareInstallers: FC<Props> = () => {
  const [value, setValue] = useState<string>(FirmwareInstallerType.Windows);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    const isValidValue: boolean = !!Object.values(FirmwareInstallerType).find(
      (v) => v === value,
    );
    if (isValidValue) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [value]);

  const handleDownload = useCallback(() => {
    const installer = FIRMWARE_INSTALLERS.find((v) => v.value === value);
    if (!installer) {
      return;
    }
    window.open(
      createServerUrl(installer.href),
      "_blank",
      "noopener,noreferrer",
    );
  }, [value]);

  return (
    <Card className="mx-auto max-w-xl">
      <CardHeader>
        <CardTitle>Программа для установки прошивки</CardTitle>
        <CardDescription>
          Как ею пользоваться показано в видео выше
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <Alert>
          <GearIcon className="h-4 w-4" />
          <AlertTitle>
            Заказать установку прошивки удаленно нашим специалистом{" "}
          </AlertTitle>
          <AlertDescription className="overflow-hidden overflow-ellipsis">
            Пишите Юрию сюда:{" "}
            <Button className="inline p-0" variant="link" asChild>
              <Link
                to="https://t.me/yuriy_atlas"
                target="_blank"
                rel="noopener noreferrer"
                reloadDocument={true}
              >
                https://t.me/yuriy_atlas
              </Link>
            </Button>
          </AlertDescription>
        </Alert>
        <Select value={value} onValueChange={setValue}>
          <SelectTrigger>
            <SelectValue placeholder="OS" />
          </SelectTrigger>
          <SelectContent>
            {FIRMWARE_INSTALLERS.map((installer, i) => (
              <SelectItem key={i} value={installer.value}>
                {installer.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </CardContent>
      <CardFooter>
        <Button
          disabled={isDisabled}
          onClick={handleDownload}
          className="w-full"
        >
          Скачать
        </Button>
      </CardFooter>
    </Card>
  );
};

export default FirmwareInstallers;
