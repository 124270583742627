export const createUrl = (
  pathname: string,
  params: URLSearchParams,
): string => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? "?" : ""}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const createServerUrl = (
  pathname: string,
  params?: URLSearchParams,
): string => {
  return new URL(
    createUrl(pathname, params ?? new URLSearchParams()),
    process.env.REACT_APP_HOST,
  ).toString();
};
