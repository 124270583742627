import { createServerUrl } from "@/utils";

export const FIRMWARE_LIST: Array<{
  title: string;
  href: string;
  statisticsKey: string;
}> = [
  {
    title: "ANTMINER L9 (контрольная плата Cvitek)",
    href: createServerUrl(
      "/uploads/atlas-l9-cv-nand-v1.2.6-rc5-install.tar.gz",
    ),
    statisticsKey: "atlas-l9-cv-nand",
  },
  {
    title: "ANTMINER L9 (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-l9-aml-nand-v1.2.6-rc5-install.tar.gz",
    ),
    statisticsKey: "atlas-l9-aml-nand",
  },
  {
    title: "ANTMINER S19 77 чипов (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "46721-atlas-s19-xil-nand-v1.2.6-rc3",
  },
  {
    title: "ANTMINER S19 126 чипов (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19-126-cv-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22992-vnishfw-s19-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 126 чипов (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19-126-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22968-vnishfw-s19-126-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 88 чипов (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22993-vnishfw-s19-88-xil-nand-v1.2.6-alpha4",
  },
  {
    title:
      "ANTMINER S19 88 чипов (двухуровневая контрольная плата Beagle Bone)",
    href: createServerUrl(
      "/uploads/atlas-s19-88-bb-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22983-vnishfw-s19-88-bb-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 88 чипов (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19-88-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22964-vnishfw-s19-88-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 Hydro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19-hydro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23006-vnishfw-s19-hydro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 A (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19a-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22994-vnishfw-s19a-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 A PRO (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19apro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23002-vnishfw-s19apro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19i-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22996-vnishfw-s19j-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J (двухуровневая контрольная плата Beagle Bone)",
    href: createServerUrl(
      "/uploads/atlas-s19j-bb-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22980-vnishfw-s19j-bb-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J+ (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19j-plus-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23009-vnishfw-s19j-plus-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J+ (двухуровневая контрольная плата Beagle Bone)",
    href: createServerUrl(
      "/uploads/atlas-s19j-plus-bb-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22985-vnishfw-s19j-plus-bb-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J+ (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19j-plus-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22971-vnishfw-s19j-plus-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23003-vnishfw-s19jpro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro (двухуровневая контрольная плата Beagle Bone)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-bb-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22981-vnishfw-s19jpro-bb-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22965-vnishfw-s19jpro-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro A (двухуровневая контрольная плата Beagle Bone)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-a-bb-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22982-vnishfw-s19jpro-a-bb-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro A (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-a-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22966-vnishfw-s19jpro-a-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro+ (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-plus-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23008-vnishfw-s19jpro-plus-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro+ (двухуровневая контрольная плата Beagle Bone)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-plus-bb-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22984-vnishfw-s19jpro-plus-bb-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 J Pro+ (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19jpro-plus-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22969-vnishfw-s19jpro-plus-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 K Pro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19kpro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23011-vnishfw-s19kpro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 K Pro (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19kpro-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22970-vnishfw-s19kpro-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19+ (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19plus-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22997-vnishfw-s19plus-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 Pro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19pro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22999-vnishfw-s19pro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 Pro A (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19pro-a-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23001-vnishfw-s19pro-a-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 Pro Hydro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19pro-hydro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22998-vnishfw-s19pro-hydro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 Pro+ Hydro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19pro-plus-hydro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23000-vnishfw-s19pro-plus-hydro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 XP (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19xp-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23004-vnishfw-s19xp-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 XP (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s19xp-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "22967-vnishfw-s19xp-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S19 XP Hydro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s19xp-hydro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23007-vnishfw-s19xp-hydro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S21 (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s21-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "39506-vnishfw-s21-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S21 Hydro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-s21-hydro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "39516-vnishfw-s21-hydro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S21 Immersion (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s21-imm-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "39508-vnishfw-s21-imm-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER S21 Pro (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-s21pro-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "39509-vnishfw-s21pro-aml-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER T19 (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-t19-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23005-vnishfw-t19-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER T19 Hydro (контрольная плата Xilink)",
    href: createServerUrl(
      "/uploads/atlas-t19-hydro-xil-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "23010-vnishfw-t19-hydro-xil-nand-v1.2.6-alpha4",
  },
  {
    title: "ANTMINER T21 (контрольная плата Amlogic)",
    href: createServerUrl(
      "/uploads/atlas-t21-aml-nand-v1.2.6-rc3-install.tar.gz",
    ),
    statisticsKey: "39507-vnishfw-t21-aml-nand-v1.2.6-alpha4",
  },
];
